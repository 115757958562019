@import '../../../styles/settings';
@import '../../../styles/cas/mixins';

.sidebar {
    flex: 0 0 auto;
    max-width: 100%;
    &.account {
        margin-bottom: 2rem;
    }
    .label-title {
        font-family: $work-sans;
        margin: rem-calc(0 0 8);
    }
    select {
        margin-top: 0;
    }
    .account-nav {
        margin-bottom: 2rem;
        .svg-icons {
            fill: $ui-gray;
            vertical-align: middle;
        }
        .acc-nav {
            &-title {
                font-size: rem-calc(22);
                display: block;
                padding-bottom: .5rem;
            }
            &-link {
                display: block;
                padding: .75rem .5rem;
                font-weight: 700;
                color: $dark-gray;
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:hover {
                    color: get-color(primary);
                    [class^="cas-i-"], [class*="cas-i"] {
                        color: rgba(get-color(primary), 0.5);
                    }
                }
                &:focus {
                    outline-color: transparent;
                    box-shadow:  0px 0px 0 2px $white, 0px 0px 4px 4px lighten($button-background, 5%);
                }
            }
        }
    }
}

.acc-nav-item {
    margin: 0 0 0.5rem;
    &.current {
        position: relative;
        .acc-nav-link {
            color: get-color(secondary);
            background: linear-gradient(0deg, get-color(secondary), get-color(secondary)) no-repeat left 100%;
            background-size: 100% 2px;
            [class^="cas-i-"], [class*="cas-i"] {
                color: rgba(get-color(secondary), 0.5);
            }
            &:hover{
                color: get-color(secondary);
                [class^="cas-i-"], [class*="cas-i"] {
                    color: rgba(get-color(secondary), 0.5);
                }
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .sidebar {
        padding-right: 2rem;
    }
}

@media screen and #{breakpoint(large)} {
    .sidebar {
        padding-right: 3rem;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .sidebar {
        padding-right: 5rem;
    }
}
