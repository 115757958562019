.dashboard-card {
  background: #ffffff;
  border: 1px solid #73bf44;
  border-top-width: 5px;
  padding: 1rem;
}

.dashboard-header {
  background-color: #e67921;
  color: #ffffff;
  padding: 1.5rem 0;
  margin-bottom: 2rem;
}
.dashboard-header .header-title {
  margin: 0;
  font-size: 1.75rem;
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
}

.dashboard-card {
  margin-bottom: 2rem;
}

.block-title {
  margin-bottom: 1rem;
}

.block-content {
  margin-bottom: 0.5rem;
}
.block-content .legend {
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  margin: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 48em) {
  .dashboard-header {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 64em) {
  .account .dashboard-card {
    padding: 2rem;
  }
}
.sidebar {
  flex: 0 0 auto;
  max-width: 100%;
}
.sidebar.account {
  margin-bottom: 2rem;
}
.sidebar .label-title {
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  margin: 0 0 0.5rem;
}
.sidebar select {
  margin-top: 0;
}
.sidebar .account-nav {
  margin-bottom: 2rem;
}
.sidebar .account-nav .svg-icons {
  fill: #9a9da6;
  vertical-align: middle;
}
.sidebar .account-nav .acc-nav-title {
  font-size: 1.375rem;
  display: block;
  padding-bottom: 0.5rem;
}
.sidebar .account-nav .acc-nav-link {
  display: block;
  padding: 0.75rem 0.5rem;
  font-weight: 700;
  color: #333333;
}
.sidebar .account-nav .acc-nav-link span {
  display: inline-block;
  vertical-align: middle;
}
.sidebar .account-nav .acc-nav-link:hover {
  color: #e67921;
}
.sidebar .account-nav .acc-nav-link:hover [class^=cas-i-], .sidebar .account-nav .acc-nav-link:hover [class*=cas-i] {
  color: rgba(230, 121, 33, 0.5);
}
.sidebar .account-nav .acc-nav-link:focus {
  outline-color: transparent;
  box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 4px 4px #e98738;
}

.acc-nav-item {
  margin: 0 0 0.5rem;
}
.acc-nav-item.current {
  position: relative;
}
.acc-nav-item.current .acc-nav-link {
  color: #559a13;
  background: linear-gradient(0deg, #559a13, #559a13) no-repeat left 100%;
  background-size: 100% 2px;
}
.acc-nav-item.current .acc-nav-link [class^=cas-i-], .acc-nav-item.current .acc-nav-link [class*=cas-i] {
  color: rgba(85, 154, 19, 0.5);
}
.acc-nav-item.current .acc-nav-link:hover {
  color: #559a13;
}
.acc-nav-item.current .acc-nav-link:hover [class^=cas-i-], .acc-nav-item.current .acc-nav-link:hover [class*=cas-i] {
  color: rgba(85, 154, 19, 0.5);
}

@media screen and (min-width: 48em) {
  .sidebar {
    padding-right: 2rem;
  }
}
@media screen and (min-width: 64em) {
  .sidebar {
    padding-right: 3rem;
  }
}
@media screen and (min-width: 75em) {
  .sidebar {
    padding-right: 5rem;
  }
}
.form-wrapper {
  box-shadow: 0px 2px 4px 0 rgba(22, 45, 57, 0.2);
  display: inline-block;
}

@media screen and (min-width: 64em) {
  .form-wrapper {
    padding: 2.5rem 3rem 3rem;
  }
}