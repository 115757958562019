@import '../../../styles/settings';
@import '../../../styles/cas/mixins';

$icon-url-path: '../#{$svg-icons-path}' !default;
%wrapper-bg {
    background: $white;
    border: 1px solid get-color('light-green');
    border-top-width: 5px;
    padding: 1rem;
}

.dashboard-header {
    background-color: get-color(primary);
    color: $white;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
    .header-title {
        margin: 0;
        font-size: rem-calc(28);
        font-family: $sec-font;
    }
}

.dashboard-card {
    @extend %wrapper-bg;
    margin-bottom: 2rem;
}

.block-title {
    margin-bottom: 1rem;
}

.block-content {
    margin-bottom: .5rem;
    .legend {
        font-family: $sec-font;
        margin: 0;
        padding-bottom: 0;
    }
}


@media screen and #{breakpoint(medium)} {
    .dashboard-header {
        margin-bottom: 4rem;
    }
}

@media screen and #{breakpoint(large)} {
    .account {
        .dashboard-card {
            padding: 2rem;
        }
    }
}
